/* You can add global styles to this file, and also import other style files */
@import "~src/assets/style/customVariables.less";
@import "~src/assets/style/context-menu.less";
@import "~src/assets/style/buttons.less";
@import "~src/assets/style/angular-tree.less";
@import "~src/assets/style/ng-select.less";
@import "~src/assets/style/material.less";
@import "~src/assets/style/slickgrid.less";
@import "~src/assets/style/toastr.less";
@import "~src/assets/style/red-hat-display.less";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss";

* {
  padding: 0;
  margin: 0;
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-size: 14px;
  font-weight: 400;
}

:root {
  // Override context-menu style attributes
  --ngx-contextmenu-padding: 5px 0;
  --ngx-contextmenu-min-width: 120px;
  --ngx-contextmenu-item-background-hover-color: #d3d3d3;
  --ngx-contextmenu-item-padding: 2px 2px;
}

.cdk-overlay-container {
  & .mdc-tooltip__surface {
    max-width: 500px;
    max-height: fit-content;
  }
}

.emph {
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
}

.nowrap {
  white-space: nowrap;
}
.flex {
  display: flex;

  &.wrap {
    flex-wrap: wrap;
  }

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}
.grow {
  flex-grow: 1;
  flex-shrink: 1;
}

.fit-x {
  min-width: 0;
}

.fit-y {
  min-height: 0;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

body,
.relative {
  // to correctly place the dropdown panel of ng-select component
  position: relative;
}

.root {
  background-color: rgb(238, 240, 248);
}

.fill-x {
  width: 100%;
}

.fill-y {
  height: 100%;
}

.hover-link:hover {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}

.tile {
  background-color: #fff;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  height: @content-height;
  overflow-y: auto;
}

.btn-primary {
  background-color: @color-button;

  &:disabled {
    background-color: @color-main-dark;
    border: @color-main-dark;
  }
}

input[type="checkbox"] {
  accent-color: @color-model;
}

.input-txt {
  padding-left: 10px;
  border-radius: 4px;
  height: 26px;
  border: 1px solid #ccc;
}

label {
  margin-bottom: 0;
}

.invalid-reference {
  color: #ff0000;
}

.fa {
  width: 16px;
}

.icon-folder {
  color: @color-folder-icon;
}
.icon-catalog {
  color: @color-catalog;
}
.icon-domain {
  color: @color-enumeration;
}
.icon-enumeration {
  color: @color-enumeration;
}
.icon-model,
.icon-export {
  color: @color-model;
}
.icon-form {
  color: @color-form;
}
