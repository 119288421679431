.mat-menu-item {
  font-family: Lato light, sans-serif !important;
  font-size: 14px;
}

.menu-item {
  cursor: pointer;
  display: flex;
  color: @color-button;

  .menu-label {
    color: black;
  }

  &:hover {
    background-color: lightgray;
  }

  &.duplicate {
    color: @color-success;
  }

  &.edit {
    color: @color-warning;
  }

  &.delete {
    color: @color-red;
  }

  &.project-color svg {
    color: @color-project-icon;
  }

  &.folder-color {
    color: @color-folder-icon;
  }

  &.disabled {
    cursor: default;
    background-color: #e0e0e0;

    & i {
      color: darkgray;
    }

    & span {
      color: darkgray;
    }
  }
}
