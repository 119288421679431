// add borders to slickgrid cells
.slick-cell {
  border-top: 0 !important;
  border-bottom: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  margin-right: -1px !important;
  padding: 2px 6px !important;

  input {
    z-index: 999;
  }
}

.slick-range-decorator {
  margin-top: 1px;
  margin-left: 2px;
}

.slick-viewport {
  border-top: 1px solid lightgray !important;
}

.slickgrid-container {
  min-height: 80vh;
}

.slick-header-menu-active {
  cursor: pointer !important;
  &:hover {
    background-color: rgb(244, 244, 244) !important;
  }
  .slick-header-menu-button {
    display: none !important;
  }
}
