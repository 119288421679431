// customize ng-select view
.search-filter {
  font-family: Lato light, sans-serif !important;
  font-size: 14px;
}

.search-filter:placeholder-shown {
  opacity: 0.3;
  border: solid darkgray 1px;
}

.dropdown-menu {
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.15);
  & > li > a {
    text-decoration: none !important;
  }
}

ng-select {
  &.pack {
    min-width: 100px;
    width: fit-content;
    max-width: 500px;

    &.no-min-width {
      min-width: 0;
    }
  }

  &.ng-select-single.ng-select-clearable
    .ng-select-container
    .ng-value-container
    .ng-input {
    padding-right: 40px !important;
  }

  &.show-end {
    &.ng-select-single.ng-select-filtered
      .ng-select-container
      .ng-value-container
      .ng-value {
      visibility: visible;
    }
    &.ng-select-opened .placeholder {
      visibility: hidden;
    }
    &:not(.ng-select-opened) .ng-input {
      opacity: 0 !important;
    }

    .placeholder {
      position: relative;
      top: 0.2px;
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }

  &:not(.pack) {
    min-width: 180px;
  }

  display: inline-block;

  &:not(.ng-select-clearable) {
    .ng-input {
      padding-right: 10px !important;
    }
  }

  &.slim.ng-select-single .ng-select-container {
    height: 26px !important;
    min-height: 26px !important;
    position: relative;

    .ng-input {
      top: 50% !important;
      transform: translateY(-50%);
    }
  }

  &.slim.ng-select-multiple .ng-select-container {
    min-height: 26px !important;
    position: relative;

    .ng-placeholder {
      top: 2px !important;
    }

    .ng-value-container {
      padding-top: 2px !important;

      .ng-value {
        margin-bottom: 2px !important;
      }
    }
  }

  .ng-select .ng-clear-wrapper {
    &,
    & .ng-clear {
      line-height: normal;
    }
  }

  .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    top: -4px;
  }
}

.ng-dropdown-panel {
  &.virtual-scroll {
    width: -webkit-fill-available !important;
  }

  min-width: fit-content !important;
}
