// RedHatDisplay Font implementation
@font-face {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url("../fonts/RedHatDisplay.woff2") format("woff2");
}

@font-face {
  font-family: "Red Hat Display";
  font-style: italic;
  font-weight: 300 900;
  font-display: swap;
  src: url("../fonts/RedHatDisplay-Italic.woff2") format("woff2");
}
