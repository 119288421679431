// customize angular-component-tree view
tree-viewport {
  height: 100%;
  padding-top: 5px;

  .angular-tree-component {
    tree-node {
      display: block;
      width: 100%;
    }

    tree-loading-component {
      display: none;
    }

    .node-content-wrapper {
      width: 100%;
      padding: 0 1px;
    }
  }
}

.node-drop-slot {
  height: 0px;
  border: none !important;
}
