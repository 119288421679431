@import "customVariables";

.mat-mcd-dialog-container {
  width: 500px;
  max-width: 500px;
  overflow: visible !important; // Able ng-select to show off the modal, important is to bypass bootstrap style
}
.mat-dialog-content {
  overflow: visible !important; // Able ng-select to show off the modal, important is to bypass bootstrap style
}

.mat-mcd-dialog-container p {
  text-align: justify;
}

.mat-mdc-dialog-surface {
  padding: @modal-padding;
}

.mat-dialog-title {
  text-align: center;
  font: 500 20px/32px "Red Hat Display", Roboto, Helvetica Neue, sans-serif;
  margin-bottom: 20px;
}

.mat-dialog-actions {
  display: flex;
  justify-content: center;
}

.mat-tooltip {
  font-size: 12px;
  max-width: none !important;
}

.mtx-tooltip {
  max-width: none !important;
}

.mat-stepper-horizontal {
  background-color: rgba(0, 0, 0, 0);
}

// customize mat-icon
mat-icon.mat-icon {
  height: auto;
  width: auto;
}

.mat-menu-panel.big-menu {
  max-width: 700px !important;
}

.mat-icon-menu {
  display: flex;
  align-items: center;
  font-size: 22px !important;
}

.wide-mat-modal .mat-mcd-dialog-container {
  max-width: 1000px !important;
}
