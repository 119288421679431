// customize button
.btn {
  margin: 3px 5px;
  padding: 0.2rem 0.75rem;
  min-height: 30px;

  &.icon {
    padding: 0px !important;
    width: 30px;
    height: 30px;
  }

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fa {
    font-size: 18px;
  }

  mat-icon {
    font-size: 20px;
  }

  app-icon {
    height: 28px;
    width: 28px;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  &.btn-square {
    width: 1.7rem;
    height: 1.7rem;
    padding: 0;
    border-color: transparent;

    .fa {
      font-size: 1rem;
    }
  }

  &.btn-light {
    &-success {
      color: #1bc5bd;
      background-color: #c9f7f5;

      &:hover {
        color: #fff;
        background-color: #1bc5bd;
      }
    }

    &-danger {
      color: #f64e60;
      background-color: #ffe2e5;

      &:hover {
        color: #fff;
        background-color: #f64e60;
      }
    }

    &-catalog {
      background-color: #fff;
      border-color: @color-catalog-border;
      color: @color-catalog;

      &:hover {
        background-color: @color-catalog;
        color: #fff;
      }
    }

    &-enum {
      background-color: #fff;
      border-color: @color-enumeration-border;
      color: @color-enumeration;

      &:hover {
        background-color: @color-enumeration;
        color: #fff;
      }
    }

    &-form {
      background-color: #fff;
      border-color: @color-form-border;
      color: @color-form;

      &:hover {
        background-color: @color-form;
        color: #fff;
      }
    }

    &-model {
      background-color: #fff;
      border-color: @color-model-border;
      color: @color-model;

      &:hover {
        background-color: @color-model;
        color: #fff;
      }
    }
  }

  &-dark {
    &-success {
      color: #fff;
      background-color: #1bc5bd;

      &:hover {
        color: #fff;
        background-color: #0bb7af;
      }
    }

    &-danger {
      color: #fff;
      background-color: #f64e60;

      &:hover {
        color: #fff;
        background-color: #ee2d41;
      }
    }

    &-primary {
      color: #fff;
      background-color: @color-main;

      &:hover {
        color: #fff;
        background-color: @color-main-dark;
      }
    }

    &-catalog {
      background-color: @color-catalog;
      color: #fff;

      &:hover {
        color: #fff;

        &:not([disabled]) {
          filter: brightness(1.2);
        }
      }
    }

    &-form {
      background-color: @color-form;
      color: #fff;

      &:hover {
        color: #fff;

        &:not([disabled]) {
          filter: brightness(1.2) saturate(0.7);
        }
      }
    }
  }

  &.delete {
    color: @color-red;

    &:hover {
      background-color: @color-red;
      border-color: @color-red;
    }
  }

  &.edit {
    color: @color-warning !important;

    &:hover {
      color: white !important;
      background-color: @color-warning !important;
      border-color: @color-warning !important;
    }
  }

  &.duplicate {
    color: @color-success;

    &:hover {
      background-color: @color-success;
      border-color: @color-success;
    }
  }
}
